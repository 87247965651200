import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { flowRight, isEmpty, isEqual } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { WithTranslation, withTranslation } from '@wix/yoshi-flow-editor';
import {
  getCategoryPath,
  NormalizedCategory,
} from '@wix/communities-blog-client-common';
import withAuth, { WithAuth } from '../../hoc/with-auth';
import {
  getCurrentMatchPathname,
  getRouteParams,
} from '../../router/router-selectors';
import { getLastUpdatedDate } from '../../selectors/app-settings-selectors';
import { getViewerAllPostsLabelTranslation } from '../../selectors/viewer-all-post-label-translation-selectors';
import { getHeaderLinks } from '../../services/get-header-links';
import {
  getViewMode,
  isSeo,
  isSSR,
} from '../../store/basic-params/basic-params-selectors';
import { getCategoriesSlice } from '../../store/categories/categories-selectors';
import { getSearchInputWidth } from '../../store/search-input/search-input-selectors';
import InternalLink from '../link/internal-link';
import MoreCategoriesDropdown from '../more-categories-dropdown';
import { connect } from '../runtime-context';
import { calculateHeaderItems, LINK_PADDING } from './calculate-header-items';
import styles from './header-navigation.scss';

type OwnProps = {};

type Props = OwnProps &
  WithTranslation &
  WithAuth &
  ConnectedProps<typeof connector>;

type State = {
  accessibilityAlert: string | null;
  visibleCategories: NormalizedCategory[];
  moreCategories: NormalizedCategory[];
  moreCategoriesMenuWidth?: number;
};

export class HeaderNavigation extends PureComponent<Props, State> {
  state: State = {
    visibleCategories: this.props.categories,
    accessibilityAlert: null,
    moreCategories: [],
  };

  componentDidMount() {
    this.updateVisibleCategories();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const propsKeys: (keyof Props)[] = [
      'isAuthenticated',
      'searchInputWidth',
      'viewMode',
      'settingsUpdated',
      'hostWidth',
    ];
    const stateKeys: (keyof State)[] = ['visibleCategories', 'moreCategories'];
    const propsChanged = propsKeys.some(
      (key) => !isEqual(prevProps[key], this.props[key]),
    );
    const stateChanged = stateKeys.some(
      (key) => !isEqual(prevState[key], this.state[key]),
    );

    if (propsChanged) {
      this.setState(
        { visibleCategories: this.props.categories, moreCategories: [] },
        this.updateVisibleCategories,
      );
    }

    if (stateChanged) {
      this.updateVisibleCategories();
    }
  }

  onNavigation = (title: string) => () => {
    this.showAccessibilityAlert(
      this.props.t('header.accessibility-alert', {
        title,
        count: this.props.categories.length,
      }),
    );
  };

  shouldRenderMoreButton = () => {
    const { moreCategories } = this.state;

    if (typeof window === 'undefined') {
      return false;
    }

    if (moreCategories.length > 0) {
      return true;
    }

    const topNavigationContainer = document.querySelector(
      '.blog-header__navigation',
    );
    const topNavigationContainerLiItems = Array.from(
      document.querySelectorAll('.blog-header__navigation > ul > li'),
    );

    if (!topNavigationContainer || topNavigationContainerLiItems.length === 0) {
      return false;
    }

    const rootWidth = topNavigationContainer.getBoundingClientRect().width;
    const renderedItemsWidth = Array.from(topNavigationContainerLiItems).reduce(
      (acc, link, index) =>
        acc +
        link.getBoundingClientRect().width +
        (index === 0 ? 0 : LINK_PADDING),
      0,
    );

    return rootWidth < renderedItemsWidth;
  };

  showAccessibilityAlert = (alert: string) => {
    this.setState({ accessibilityAlert: alert });
  };

  updateVisibleCategories() {
    const nextState = calculateHeaderItems(
      this.props.categories,
      this.state.visibleCategories,
    );
    !isEmpty(nextState) && this.setState(nextState);
  }

  render() {
    const { moreCategoriesMenuWidth } = this.state;
    const links = getHeaderLinks({
      categories: this.state.visibleCategories,
      feedTitle: this.props.feedTitle,
      categoryPath: this.props.categoryPath,
      withLinkToAllPosts: true,
    });
    const linksInMoreDropdown = getHeaderLinks({
      categories: this.state.moreCategories,
      feedTitle: this.props.feedTitle,
      categoryPath: this.props.categoryPath,
      withLinkToAllPosts: false,
    });

    return (
      <nav
        className={classNames(
          styles.topNavigation,
          'blog-header__navigation',
          'blog-navigation-container-font',
        )}
        aria-label={this.props.t('blog-mobile-header.title.blog')}
      >
        {this.state.accessibilityAlert && (
          <div role="alert" className={styles.visuallyHidden}>
            {this.state.accessibilityAlert}
          </div>
        )}
        <ul className={styles.container}>
          {links.map((link, index) => {
            const isActive = this.props.currentPathDecoded === link.path;
            const linkClassNames = classNames(
              'blog-navigation-container-color blog-navigation-container-font ',
              'blog-navigation-link-hover-color',
              {
                'blog-navigation-link-active-color': isActive,
              },
            );

            return (
              <li key={link.key} data-hook={`header-navigation__${link.path}`}>
                <InternalLink
                  className={linkClassNames}
                  to={link.path}
                  addHoverClasses={false}
                  isActive={isActive}
                  onNavigation={() => this.showAccessibilityAlert(link.text)}
                >
                  {link.text}
                </InternalLink>
              </li>
            );
          })}
          {this.shouldRenderMoreButton() ? (
            <li id="categories-more-button">
              <MoreCategoriesDropdown
                currentPath={this.props.currentPath}
                items={linksInMoreDropdown}
                width={moreCategoriesMenuWidth}
                onNavigation={(link) => this.showAccessibilityAlert(link.text)}
              />
            </li>
          ) : null}
        </ul>
      </nav>
    );
  }
}

const connector = connect((state, ownProps, actions, host) => {
  const { page } = getRouteParams(state) || {};
  const currentMatchPathname = getCurrentMatchPathname(state);
  const currentPath = page
    ? currentMatchPathname.replace(`/page/${page}`, '')
    : currentMatchPathname;

  return {
    categories: getCategoriesSlice(state, 30),
    currentPath,
    hostWidth: host.dimensions.width,
    currentPathDecoded: decodeURIComponent(currentPath),
    searchInputWidth: getSearchInputWidth(state),
    feedTitle: (getViewerAllPostsLabelTranslation(state) ||
      state.tpaSettings.settings?.allPostsFeedLabel) as string,
    viewMode: getViewMode(state),
    settingsUpdated: getLastUpdatedDate(state),
    categoryPath: getCategoryPath(state),
    isSSR: isSSR(state),
    isSEO: isSeo(state),
    paginationClearCollection: actions.paginationClearCollection,
  };
});

export default flowRight(
  connector,
  withAuth,
  withTranslation(),
)(HeaderNavigation);
